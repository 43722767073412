var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('h4',[_vm._v("NOC For Amendment Form")]),_c('form',{ref:"formContainer",staticClass:"form-horizontal vld-parent",on:{"submit":function($event){$event.preventDefault();return _vm.newAmendmentRequest.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Type*")]),_c('b-form-radio-group',{staticClass:"mb-3",class:{
                            'is-invalid':
                                _vm.submitted &&
                                _vm.$v.amendment.amendment_type.$error,
                        },attrs:{"options":_vm.amendmentTypes,"value-field":"item","text-field":"name","disabled-field":"notEnabled"},model:{value:(_vm.amendment.amendment_type),callback:function ($$v) {_vm.$set(_vm.amendment, "amendment_type", $$v)},expression:"amendment.amendment_type"}}),(
                            _vm.submitted && _vm.$v.amendment.amendment_type.$error
                        )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.amendment.amendment_type.required)?_c('span',[_vm._v("Please choose an option")]):_vm._e()]):_vm._e()],1)])]),(_vm.amendment.amendment_type)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Unit*")]),_c('b-form-select',{class:{
                                'is-invalid':
                                    _vm.submitted &&
                                    _vm.$v.amendment.unit_id.$error,
                            },attrs:{"id":"ownerfname","name":"ownerfname","options":_vm.mappedUserUnits},model:{value:(_vm.amendment.unit_id),callback:function ($$v) {_vm.$set(_vm.amendment, "unit_id", $$v)},expression:"amendment.unit_id"}}),(_vm.submitted && _vm.$v.amendment.unit_id.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.amendment.unit_id.required)?_c('span',[_vm._v("Unit is required")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Number Of Buyers*")]),_c('b-form-select',{class:{
                                'is-invalid':
                                    _vm.submitted &&
                                    _vm.$v.amendment.no_of_owners.$error,
                            },attrs:{"id":"ownerfname","name":"ownerfname","options":_vm.buyerCount},on:{"change":function($event){return _vm.addOwnerForm()}},model:{value:(_vm.amendment.no_of_owners),callback:function ($$v) {_vm.$set(_vm.amendment, "no_of_owners", $$v)},expression:"amendment.no_of_owners"}}),(
                                _vm.submitted &&
                                _vm.$v.amendment.no_of_owners.$error
                            )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.amendment.no_of_owners.required)?_c('span',[_vm._v("Number of owners required")]):_vm._e()]):_vm._e()],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Title Deed*")]),_c('b-form-file',{staticClass:"mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.amendment.title_deed.$error,
                        },attrs:{"placeholder":"Choose File","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf"},on:{"change":_vm.handleTitleDeed}}),(_vm.submitted && _vm.$v.amendment.title_deed.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.amendment.title_deed.required)?_c('span',[_vm._v("Title deed is required")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"col-lg-6"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Bank Release Letter (If Any)")]),_c('b-form-file',{staticClass:"mb-3",attrs:{"placeholder":"Choose File","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf"},on:{"change":_vm.handleReleaseLetter}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Buyer Mortgage Bank Name (If Any)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.amendment.buyer_mortgage_bank_name),expression:"amendment.buyer_mortgage_bank_name"}],staticClass:"form-control mb-3",attrs:{"type":"text","id":"username"},domProps:{"value":(_vm.amendment.buyer_mortgage_bank_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.amendment, "buyer_mortgage_bank_name", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Buyer Bank Offer Letter (If any)")]),_c('b-form-file',{staticClass:"mb-3",attrs:{"placeholder":"Choose File","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf"},on:{"change":_vm.handleOfferLetter}})],1),_c('div',{staticClass:"col-lg-4"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("MOU*")]),_c('b-form-file',{staticClass:"mb-3",class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.amendment.mou.$error,
                        },attrs:{"placeholder":"Choose File","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf"},on:{"change":_vm.handleMou}}),(_vm.submitted && _vm.$v.amendment.mou.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.amendment.mou.required)?_c('span',[_vm._v("MOU is required")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"col-lg-4"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Third Pary Liability Insurance*")]),_c('b-form-file',{staticClass:"mb-3",class:{
                            'is-invalid':
                                _vm.submitted &&
                                _vm.$v.amendment.third_party_liability_insurance
                                    .$error,
                        },attrs:{"placeholder":"Choose File","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf"},on:{"change":_vm.handleInsurance}}),(
                            _vm.submitted &&
                            _vm.$v.amendment.third_party_liability_insurance
                                .$error
                        )?_c('div',{staticClass:"invalid-feedback"},[(
                                !_vm.$v.amendment
                                    .third_party_liability_insurance
                                    .required
                            )?_c('span',[_vm._v("Thirld pary liability insurance is required")]):_vm._e()]):_vm._e()],1)]),_vm._l((_vm.amendment.buyers),function(buyer,index){return _c('div',{key:index,staticClass:"mt-3 mb-3",attrs:{"set":(_vm.v = _vm.$v.amendment.buyers.$each[index])}},[(_vm.amendment.no_of_owners)?_c('div',[_c('h4',[_vm._v("Buyer "+_vm._s(index + 1))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Buyer Name*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(buyer.buyer_name),expression:"buyer.buyer_name"}],staticClass:"form-control mb-3",class:{
                                        'is-invalid':
                                            _vm.submitted &&
                                            _vm.v.buyer_name.$error,
                                    },attrs:{"type":"text","id":"username"},domProps:{"value":(buyer.buyer_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(buyer, "buyer_name", $event.target.value)}}}),(_vm.submitted && _vm.v.buyer_name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.v.buyer_name.required)?_c('span',[_vm._v("Buyer name required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Buyer City*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(buyer.buyer_city),expression:"buyer.buyer_city"}],staticClass:"form-control mb-3",class:{
                                        'is-invalid':
                                            _vm.submitted &&
                                            _vm.v.buyer_city.$error,
                                    },attrs:{"type":"text","id":"username"},domProps:{"value":(buyer.buyer_city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(buyer, "buyer_city", $event.target.value)}}}),(_vm.submitted && _vm.v.buyer_city.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.v.buyer_city.required)?_c('span',[_vm._v("City is required")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Buyer Country*")]),_c('b-form-select',{class:{
                                        'is-invalid':
                                            _vm.submitted &&
                                            _vm.v.buyer_country.$error,
                                    },attrs:{"id":"ownerfname","name":"ownerfname","options":_vm.mappedCountries},model:{value:(buyer.buyer_country),callback:function ($$v) {_vm.$set(buyer, "buyer_country", $$v)},expression:"buyer.buyer_country"}}),(
                                        _vm.submitted && _vm.v.buyer_country.$error
                                    )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.v.buyer_country.required)?_c('span',[_vm._v("Nationality required")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Buyer PO Box*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(buyer.buyer_po_box),expression:"buyer.buyer_po_box"}],staticClass:"form-control mb-3",class:{
                                        'is-invalid':
                                            _vm.submitted &&
                                            _vm.v.buyer_po_box.$error,
                                    },attrs:{"type":"text","id":"username"},domProps:{"value":(buyer.buyer_po_box)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(buyer, "buyer_po_box", $event.target.value)}}}),(
                                        _vm.submitted && _vm.v.buyer_po_box.$error
                                    )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.v.buyer_po_box.required)?_c('span',[_vm._v("Po box required")]):_vm._e(),(!_vm.v.buyer_po_box.numeric)?_c('span',[_vm._v("Only numeric values allowed")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Buyer Phone Number*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(buyer.buyer_phone_no),expression:"buyer.buyer_phone_no"}],staticClass:"form-control mb-3",class:{
                                        'is-invalid':
                                            _vm.submitted &&
                                            _vm.v.buyer_phone_no.$error,
                                    },attrs:{"type":"text","id":"username"},domProps:{"value":(buyer.buyer_phone_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(buyer, "buyer_phone_no", $event.target.value)}}}),(
                                        _vm.submitted && _vm.v.buyer_phone_no.$error
                                    )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.v.buyer_phone_no.required)?_c('span',[_vm._v("Phone number required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Buyer Fax Number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(buyer.buyer_fax_no),expression:"buyer.buyer_fax_no"}],staticClass:"form-control mb-3",attrs:{"type":"text","id":"username"},domProps:{"value":(buyer.buyer_fax_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(buyer, "buyer_fax_no", $event.target.value)}}})])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Buyer Mobile Number*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(buyer.buyer_mobile_no),expression:"buyer.buyer_mobile_no"}],staticClass:"form-control mb-3",class:{
                                        'is-invalid':
                                            _vm.submitted &&
                                            _vm.v.buyer_mobile_no.$error,
                                    },attrs:{"type":"text","id":"username"},domProps:{"value":(buyer.buyer_mobile_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(buyer, "buyer_mobile_no", $event.target.value)}}}),(
                                        _vm.submitted &&
                                        _vm.v.buyer_mobile_no.$error
                                    )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.v.buyer_mobile_no.required)?_c('span',[_vm._v("Mobile number required")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Buyer Email*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(buyer.buyer_email),expression:"buyer.buyer_email"}],staticClass:"form-control mb-3",class:{
                                        'is-invalid':
                                            _vm.submitted &&
                                            _vm.v.buyer_email.$error,
                                    },attrs:{"type":"email","id":"username"},domProps:{"value":(buyer.buyer_email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(buyer, "buyer_email", $event.target.value)}}}),(_vm.submitted && _vm.v.buyer_email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.v.buyer_email.required)?_c('span',[_vm._v("Emai is required")]):_vm._e(),(!_vm.v.buyer_email.email)?_c('span',[_vm._v("Please provide valid email")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Buyer Physical Address*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(buyer.buyer_physical_address),expression:"buyer.buyer_physical_address"}],staticClass:"form-control mb-3",class:{
                                        'is-invalid':
                                            _vm.submitted &&
                                            _vm.v.buyer_physical_address.$error,
                                    },attrs:{"type":"text","id":"username"},domProps:{"value":(buyer.buyer_physical_address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(buyer, "buyer_physical_address", $event.target.value)}}}),(
                                        _vm.submitted &&
                                        _vm.v.buyer_physical_address.$error
                                    )?_c('div',{staticClass:"invalid-feedback"},[(
                                            !_vm.v.buyer_physical_address
                                                .required
                                        )?_c('span',[_vm._v("Address is required")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Buyer Passport*")]),_c('b-form-file',{staticClass:"mb-3",class:{
                                    'is-invalid':
                                        _vm.submitted &&
                                        _vm.v.buyer_passport.$error,
                                },attrs:{"placeholder":"Choose File","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf"},on:{"change":function($event){return _vm.handleBuyerPassport($event, index)}}}),(_vm.submitted && _vm.v.buyer_passport.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.v.buyer_passport.required)?_c('span',[_vm._v("Passport is required")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"col-lg-4"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Buyer Visa*")]),_c('b-form-file',{staticClass:"mb-3",class:{
                                    'is-invalid':
                                        _vm.submitted && _vm.v.buyer_visa.$error,
                                },attrs:{"placeholder":"Choose File","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf"},on:{"change":function($event){return _vm.handleBuyerVisa($event, index)}}}),(_vm.submitted && _vm.v.buyer_visa.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.v.buyer_visa.required)?_c('span',[_vm._v("Visa is required")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"col-lg-4"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Buyer Emirates Id*")]),_c('b-form-file',{staticClass:"mb-3",class:{
                                    'is-invalid':
                                        _vm.submitted && _vm.v.buyer_eid.$error,
                                },attrs:{"placeholder":"Choose File","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf"},on:{"change":function($event){return _vm.handleBuyerEmiratesId($event, index)}}}),(_vm.submitted && _vm.v.buyer_eid.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.v.buyer_eid.required)?_c('span',[_vm._v("Emirates id is required")]):_vm._e()]):_vm._e()],1)])]):_vm._e()])})],2):_vm._e(),_c('submitButton')],1),_c('paymentModal',{ref:"paymentWindow",attrs:{"requestId":_vm.requestId,"referenceNumber":_vm.refernceNumber,"page":"Amendment","modalTitle":_vm.modalTitle,"adminFees":_vm.adminFees,"urgentFees":_vm.urgentFees,"securityDepFees":_vm.securityDepFees,"adminVatFees":_vm.adminVatFees}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <Layout>
        <h4>NOC For Amendment Form</h4>
        <form
            class="form-horizontal vld-parent"
            @submit.prevent="newAmendmentRequest"
            ref="formContainer"
        >
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Type*</label>
                        <b-form-radio-group
                            v-model="amendment.amendment_type"
                            :options="amendmentTypes"
                            class="mb-3"
                            value-field="item"
                            text-field="name"
                            disabled-field="notEnabled"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.amendment.amendment_type.$error,
                            }"
                        ></b-form-radio-group>

                        <div
                            v-if="
                                submitted && $v.amendment.amendment_type.$error
                            "
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.amendment.amendment_type.required"
                                >Please choose an option</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="amendment.amendment_type">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="ownerfname">Unit*</label>
                            <b-form-select
                                id="ownerfname"
                                name="ownerfname"
                                v-model="amendment.unit_id"
                                :options="mappedUserUnits"
                                :class="{
                                    'is-invalid':
                                        submitted &&
                                        $v.amendment.unit_id.$error,
                                }"
                            ></b-form-select>

                            <div
                                v-if="submitted && $v.amendment.unit_id.$error"
                                class="invalid-feedback"
                            >
                                <span v-if="!$v.amendment.unit_id.required"
                                    >Unit is required</span
                                >
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <label for="ownerfname">Number Of Buyers*</label>
                            <b-form-select
                                id="ownerfname"
                                @change="addOwnerForm()"
                                v-model="amendment.no_of_owners"
                                name="ownerfname"
                                :options="buyerCount"
                                :class="{
                                    'is-invalid':
                                        submitted &&
                                        $v.amendment.no_of_owners.$error,
                                }"
                            ></b-form-select>
                            <div
                                v-if="
                                    submitted &&
                                    $v.amendment.no_of_owners.$error
                                "
                                class="invalid-feedback"
                            >
                                <span v-if="!$v.amendment.no_of_owners.required"
                                    >Number of owners required</span
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6">
                        <label for="ownerfname">Title Deed*</label>
                        <b-form-file
                            placeholder="Choose File"
                            drop-placeholder="Drop file here..."
                            @change="handleTitleDeed"
                            class="mb-3"
                            accept="image/png, image/gif, image/jpeg,application/pdf"
                            :class="{
                                'is-invalid':
                                    submitted && $v.amendment.title_deed.$error,
                            }"
                        ></b-form-file>
                        <div
                            v-if="submitted && $v.amendment.title_deed.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.amendment.title_deed.required"
                                >Title deed is required</span
                            >
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <label for="ownerfname"
                            >Bank Release Letter (If Any)</label
                        >
                        <b-form-file
                            placeholder="Choose File"
                            drop-placeholder="Drop file here..."
                            @change="handleReleaseLetter"
                            class="mb-3"
                            accept="image/png, image/gif, image/jpeg,application/pdf"
                        ></b-form-file>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label for="ownerfname"
                                >Buyer Mortgage Bank Name (If Any)</label
                            >
                            <input
                                type="text"
                                class="form-control mb-3"
                                id="username"
                                v-model="amendment.buyer_mortgage_bank_name"
                            />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4">
                        <label for="ownerfname"
                            >Buyer Bank Offer Letter (If any)</label
                        >
                        <b-form-file
                            placeholder="Choose File"
                            drop-placeholder="Drop file here..."
                            @change="handleOfferLetter"
                            class="mb-3"
                            accept="image/png, image/gif, image/jpeg,application/pdf"
                        ></b-form-file>
                    </div>
                    <div class="col-lg-4">
                        <label for="ownerfname">MOU*</label>
                        <b-form-file
                            placeholder="Choose File"
                            drop-placeholder="Drop file here..."
                            @change="handleMou"
                            class="mb-3"
                            accept="image/png, image/gif, image/jpeg,application/pdf"
                            :class="{
                                'is-invalid':
                                    submitted && $v.amendment.mou.$error,
                            }"
                        ></b-form-file>
                        <div
                            v-if="submitted && $v.amendment.mou.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.amendment.mou.required"
                                >MOU is required</span
                            >
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <label for="ownerfname"
                            >Third Pary Liability Insurance*</label
                        >
                        <b-form-file
                            placeholder="Choose File"
                            drop-placeholder="Drop file here..."
                            @change="handleInsurance"
                            class="mb-3"
                            accept="image/png, image/gif, image/jpeg,application/pdf"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.amendment.third_party_liability_insurance
                                        .$error,
                            }"
                        ></b-form-file>
                        <div
                            v-if="
                                submitted &&
                                $v.amendment.third_party_liability_insurance
                                    .$error
                            "
                            class="invalid-feedback"
                        >
                            <span
                                v-if="
                                    !$v.amendment
                                        .third_party_liability_insurance
                                        .required
                                "
                                >Thirld pary liability insurance is
                                required</span
                            >
                        </div>
                    </div>
                </div>

                <div
                    v-for="(buyer, index) in amendment.buyers"
                    :key="index"
                    class="mt-3 mb-3"
                    :set="(v = $v.amendment.buyers.$each[index])"
                >
                    <div v-if="amendment.no_of_owners">
                        <h4>Buyer {{ index + 1 }}</h4>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="ownerfname">Buyer Name*</label>
                                    <input
                                        type="text"
                                        class="form-control mb-3"
                                        v-model="buyer.buyer_name"
                                        id="username"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v.buyer_name.$error,
                                        }"
                                    />
                                    <div
                                        v-if="submitted && v.buyer_name.$error"
                                        class="invalid-feedback"
                                    >
                                        <span v-if="!v.buyer_name.required"
                                            >Buyer name required</span
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="ownerfname">Buyer City*</label>
                                    <input
                                        type="text"
                                        class="form-control mb-3"
                                        id="username"
                                        v-model="buyer.buyer_city"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v.buyer_city.$error,
                                        }"
                                    />
                                    <div
                                        v-if="submitted && v.buyer_city.$error"
                                        class="invalid-feedback"
                                    >
                                        <span v-if="!v.buyer_city.required"
                                            >City is required</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="ownerfname"
                                        >Buyer Country*</label
                                    >
                                    <b-form-select
                                        id="ownerfname"
                                        name="ownerfname"
                                        :options="mappedCountries"
                                        v-model="buyer.buyer_country"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v.buyer_country.$error,
                                        }"
                                    ></b-form-select>
                                    <div
                                        v-if="
                                            submitted && v.buyer_country.$error
                                        "
                                        class="invalid-feedback"
                                    >
                                        <span v-if="!v.buyer_country.required"
                                            >Nationality required</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="ownerfname"
                                        >Buyer PO Box*</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control mb-3"
                                        id="username"
                                        v-model="buyer.buyer_po_box"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v.buyer_po_box.$error,
                                        }"
                                    />
                                    <div
                                        v-if="
                                            submitted && v.buyer_po_box.$error
                                        "
                                        class="invalid-feedback"
                                    >
                                        <span v-if="!v.buyer_po_box.required"
                                            >Po box required</span
                                        >
                                        <span v-if="!v.buyer_po_box.numeric"
                                            >Only numeric values allowed</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="ownerfname"
                                        >Buyer Phone Number*</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control mb-3"
                                        id="username"
                                        v-model="buyer.buyer_phone_no"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v.buyer_phone_no.$error,
                                        }"
                                    />
                                    <div
                                        v-if="
                                            submitted && v.buyer_phone_no.$error
                                        "
                                        class="invalid-feedback"
                                    >
                                        <span v-if="!v.buyer_phone_no.required"
                                            >Phone number required</span
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="ownerfname"
                                        >Buyer Fax Number</label
                                    >
                                    <input
                                        v-model="buyer.buyer_fax_no"
                                        type="text"
                                        class="form-control mb-3"
                                        id="username"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="ownerfname"
                                        >Buyer Mobile Number*</label
                                    >
                                    <input
                                        type="text"
                                        v-model="buyer.buyer_mobile_no"
                                        class="form-control mb-3"
                                        id="username"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v.buyer_mobile_no.$error,
                                        }"
                                    />
                                    <div
                                        v-if="
                                            submitted &&
                                            v.buyer_mobile_no.$error
                                        "
                                        class="invalid-feedback"
                                    >
                                        <span v-if="!v.buyer_mobile_no.required"
                                            >Mobile number required</span
                                        >
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="ownerfname">Buyer Email*</label>
                                    <input
                                        type="email"
                                        class="form-control mb-3"
                                        v-model="buyer.buyer_email"
                                        id="username"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v.buyer_email.$error,
                                        }"
                                    />
                                    <div
                                        v-if="submitted && v.buyer_email.$error"
                                        class="invalid-feedback"
                                    >
                                        <span v-if="!v.buyer_email.required"
                                            >Emai is required</span
                                        >
                                        <span v-if="!v.buyer_email.email"
                                            >Please provide valid email</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label for="ownerfname"
                                        >Buyer Physical Address*</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control mb-3"
                                        v-model="buyer.buyer_physical_address"
                                        id="username"
                                        :class="{
                                            'is-invalid':
                                                submitted &&
                                                v.buyer_physical_address.$error,
                                        }"
                                    />
                                    <div
                                        v-if="
                                            submitted &&
                                            v.buyer_physical_address.$error
                                        "
                                        class="invalid-feedback"
                                    >
                                        <span
                                            v-if="
                                                !v.buyer_physical_address
                                                    .required
                                            "
                                            >Address is required</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-4">
                                <label for="ownerfname">Buyer Passport*</label>
                                <b-form-file
                                    placeholder="Choose File"
                                    drop-placeholder="Drop file here..."
                                    @change="handleBuyerPassport($event, index)"
                                    class="mb-3"
                                    accept="image/png, image/gif, image/jpeg,application/pdf"
                                    :class="{
                                        'is-invalid':
                                            submitted &&
                                            v.buyer_passport.$error,
                                    }"
                                ></b-form-file>
                                <div
                                    v-if="submitted && v.buyer_passport.$error"
                                    class="invalid-feedback"
                                >
                                    <span v-if="!v.buyer_passport.required"
                                        >Passport is required</span
                                    >
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <label for="ownerfname">Buyer Visa*</label>
                                <b-form-file
                                    placeholder="Choose File"
                                    drop-placeholder="Drop file here..."
                                    @change="handleBuyerVisa($event, index)"
                                    class="mb-3"
                                    accept="image/png, image/gif, image/jpeg,application/pdf"
                                    :class="{
                                        'is-invalid':
                                            submitted && v.buyer_visa.$error,
                                    }"
                                ></b-form-file>
                                <div
                                    v-if="submitted && v.buyer_visa.$error"
                                    class="invalid-feedback"
                                >
                                    <span v-if="!v.buyer_visa.required"
                                        >Visa is required</span
                                    >
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <label for="ownerfname"
                                    >Buyer Emirates Id*</label
                                >
                                <b-form-file
                                    placeholder="Choose File"
                                    drop-placeholder="Drop file here..."
                                    @change="
                                        handleBuyerEmiratesId($event, index)
                                    "
                                    class="mb-3"
                                    accept="image/png, image/gif, image/jpeg,application/pdf"
                                    :class="{
                                        'is-invalid':
                                            submitted && v.buyer_eid.$error,
                                    }"
                                ></b-form-file>
                                <div
                                    v-if="submitted && v.buyer_eid.$error"
                                    class="invalid-feedback"
                                >
                                    <span v-if="!v.buyer_eid.required"
                                        >Emirates id is required</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="mt-3 mb-3">
                <button type="submit" class="btn btn-primary">Submit</button>
            </div> -->
            <submitButton />
        </form>
        <paymentModal
            :requestId="requestId"
            :referenceNumber="refernceNumber"
            ref="paymentWindow"
            page="Amendment"
            :modalTitle="modalTitle"
            :adminFees="adminFees"
            :urgentFees="urgentFees"
            :securityDepFees="securityDepFees"
            :adminVatFees="adminVatFees"
        />
    </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import alertMixin from "../../../mixins/alertMixin";
import { BASE_URL, REQUEST_TYPES } from "../../common";
import axios from "axios";
import { required, email, numeric } from "vuelidate/lib/validators";
import paymentModal from "../payment/paymentModal.vue";
import submitButton from "../../../components/submitButton.vue";

export default {
    mixins: [alertMixin],
    components: {
        Layout,
        paymentModal,
        submitButton,
    },
    validations: {
        amendment: {
            title_deed: { required },
            unit_id: { required },
            buyers: {
                $each: {
                    buyer_city: { required },
                    buyer_country: { required },
                    buyer_eid: { required },
                    buyer_email: { required, email },
                    buyer_mobile_no: { required },
                    buyer_name: { required },
                    buyer_passport: { required },
                    buyer_phone_no: { required },
                    buyer_physical_address: { required },
                    buyer_po_box: { required, numeric },
                    buyer_visa: { required },
                },
            },
            mou: { required },
            no_of_owners: { required },
            third_party_liability_insurance: { required },
            amendment_type: { required },
        },
    },
    data() {
        return {
            requestId: "",
            refernceNumber: "",
            adminFees: "",
            adminVatFees: "",
            securityDepFees: "",
            urgentFees: "",
            serviceFees: "",
            modalTitle: "",
            payment: "",
            selected: "",
            submitted: false,
            buyerCount: [
                { value: null, text: "Choose a number" },
                { value: "1", text: "1" },
                { value: "2", text: "2" },
                { value: "3", text: "3" },
                { value: "4", text: "4" },
                { value: "5", text: "5" },
                { value: "6", text: "6" },
                { value: "7", text: "7" },
                { value: "8", text: "8" },
                { value: "9", text: "9" },
                { value: "10", text: "10" },
            ],
            amendmentTypes: [
                { item: "GIFT", name: "Noc amendment for gift" },
                { item: "SALE", name: "Noc amendment for sale" },
            ],

            amendment: {
                buyer_bank_offer_letter: "",
                buyer_bank_offer_letter_filename: "",
                buyer_mortgage_bank_name: "",
                owner_bank_release_letter: "",
                owner_bank_release_letter_filename: "",
                buyers: [
                    {
                        buyer_city: "",
                        buyer_country: "",
                        buyer_eid: "",
                        buyer_eid_filename: "",
                        buyer_email: "",
                        buyer_fax_no: "",
                        buyer_mobile_no: "",
                        buyer_name: "",
                        buyer_passport: "",
                        buyer_passport_filename: "",
                        buyer_phone_no: "",
                        buyer_physical_address: "",
                        buyer_po_box: "",
                        buyer_visa: "",
                        buyer_visa_filename: "",
                    },
                ],
                mou: "",
                mou_filename: "",
                no_of_owners: "",
                third_party_liability_insurance: "",
                third_party_liability_insurance_filename: "",
                title_deed: "",
                title_deed_filename: "",
                unit_id: "",
                amendment_type: "",
            },
        };
    },
    mounted() {
        this.$store.dispatch("apidata/fetchCountries");
        this.getTermsConditions();
    },
    methods: {
        handleNoc() {
            return;
        },
        getTermsConditions() {
            if (localStorage.cs_user_token) {
                let payload = {
                    request_type: REQUEST_TYPES.find(
                        (x) => x.type === "Amendment"
                    ).id,
                };
                axios
                    .post(
                        `${BASE_URL}getrequeststermsconditions?token=${localStorage.cs_user_token}`,
                        payload
                    )
                    .then((res) => {
                        this.adminFees = res.data.response.service_fees;
                        this.serviceFees = res.data.response.service_fees;
                        this.urgentFees = res.data.response.urgent_fees;
                        this.adminVatFees = res.data.response.admin_vat_fees;
                        this.securityDepFees =
                            res.data.response.security_deposit;
                    });
            }
        },
        newAmendmentRequest() {
            console.log("form submission");
            this.submitted = true;
            this.$v.$touch();
            if (!this.$v.$error) {
                console.log(this.amendment);
                if (localStorage.cs_user_token) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    axios
                        .post(
                            `${BASE_URL}amendments?token=${localStorage.cs_user_token}`,
                            this.amendment
                        )
                        .then((res) => {
                            // this.resetFields();
                            this.requestId = res.data.response.request_id;
                            this.refernceNumber = res.data.response.ref_no;
                            console.log(res.data.response);
                            this.modalTitle =
                                "Payment for  " +
                                " " +
                                res.data.response.ref_no;
                            loader.hide();
                            this.$refs.paymentWindow.showModal();
                        })
                        // eslint-disable-next-line no-unused-vars
                        .catch((err) => {
                            loader.hide();
                        });
                }
            }
        },
        addOwnerForm() {
            this.amendment.buyers = [];

            for (let index = 0; index < this.amendment.no_of_owners; index++) {
                // console.log(index);
                this.amendment.buyers.push({
                    buyer_city: "",
                    buyer_country: "",
                    buyer_eid: "",
                    buyer_eid_filename: "",
                    buyer_email: "",
                    buyer_fax_no: "",
                    buyer_mobile_no: "",
                    buyer_name: "",
                    buyer_passport: "",
                    buyer_passport_filename: "",
                    buyer_phone_no: "",
                    buyer_physical_address: "",
                    buyer_po_box: "",
                    buyer_visa: "",
                    buyer_visa_filename: "",
                });
            }
        },

        handleTitleDeed(e) {
            let reader = new FileReader();
            this.amendment.title_deed_filename = e.target.files[0].name;
            reader.onload = (e) => {
                this.amendment.title_deed = e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleOfferLetter(e) {
            let reader = new FileReader();
            this.amendment.buyer_bank_offer_letter_filename =
                e.target.files[0].name;

            reader.onload = (e) => {
                this.amendment.buyer_bank_offer_letter =
                    e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleMou(e) {
            let reader = new FileReader();
            this.amendment.mou_filename = e.target.files[0].name;

            reader.onload = (e) => {
                this.amendment.mou = e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleInsurance(e) {
            let reader = new FileReader();
            this.amendment.third_party_liability_insurance_filename =
                e.target.files[0].name;

            reader.onload = (e) => {
                this.amendment.third_party_liability_insurance =
                    e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleReleaseLetter(e) {
            let reader = new FileReader();
            this.amendment.owner_bank_release_letter_filename =
                e.target.files[0].name;

            reader.onload = (e) => {
                this.amendment.owner_bank_release_letter =
                    e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleBuyerPassport(e, index) {
            let reader = new FileReader();
            this.amendment.buyers[index].buyer_passport_filename =
                e.target.files[0].name;
            reader.onload = (e) => {
                this.amendment.buyers[index].buyer_passport =
                    e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleBuyerVisa(e, index) {
            let reader = new FileReader();
            this.amendment.buyers[index].buyer_visa_filename =
                e.target.files[0].name;
            reader.onload = (e) => {
                this.amendment.buyers[index].buyer_visa =
                    e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleBuyerEmiratesId(e, index) {
            let reader = new FileReader();
            this.amendment.buyers[index].buyer_eid_filename =
                e.target.files[0].name;
            reader.onload = (e) => {
                this.amendment.buyers[index].buyer_eid =
                    e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },
    },
    computed: {
        countries() {
            return this.$store.getters["apidata/getCountries"];
        },
        mappedCountries() {
            return this.countries.map((ele) => {
                return {
                    value: ele.id,
                    text: ele.name,
                };
            });
        },
        userUnits() {
            return this.$store.getters["apidata/getUserInfo"];
            // return JSON.parse(localStorage.user_info).units;
        },
        mappedUserUnits() {
            return this.userUnits?.units?.map((ele) => {
                return {
                    value: ele.unit_id,
                    text: ele.unit_no,
                    payment: ele.payment_type,
                };
            });
        },
    },
};
</script>
<style scoped></style>
